import { createContext, useContext } from "react";

const themeByColor: { white: Theme, dark: Theme } = {
  white: {
    theme: 'white',
    background: 'white',
    text: 'black',
    title: '#2bcbba',
  },
  dark: {
    theme: 'dark',
    background: '#0d1117',
    text: 'white',
    title: '#26de81'
  }
}

export type ThemeColor = 'white' | 'dark';

export interface Theme {
  theme: ThemeColor;
  background: string;
  text: string;
  title: string;
}

const ThemeContext = createContext<Theme>(themeByColor.white)

export function useTheme() : Theme {
  return useContext(ThemeContext);
}

interface ThemeProviderProps {
  value: ThemeColor;
  children: React.ReactNode;
}

export function ThemeProvider({ value, children }: ThemeProviderProps) {
  return (
    <ThemeContext.Provider value={themeByColor[value] || themeByColor.white}>
      {children}
    </ThemeContext.Provider>
  );
}