import styled from 'styled-components';

interface AvatarProps {}

function Avatar(props: AvatarProps) {
  return (
    <StyledAvatar>
      <img src={"/avatar.jpeg"} alt="Naoufel Berrada" />
    </StyledAvatar>
  )
}

const StyledAvatar = styled.div`
display: inline-block;
  height: 100%;
  width: fit-content;
  min-width: fit-content;
  border-radius: 10px;
  border: 2px solid rgba(0, 0, 0, 0.1);
  overflow: hidden;
  
  img {
    height: 100%;
  }
`

export default Avatar;