import { faLink } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import { Theme, useTheme } from "../../context/Theme";

interface LinkProps {
  url: string;
  text: string;
}

function Link({ url, text }: LinkProps) {
  const theme = useTheme();

  return (
    <StyledLink {...theme} href={url} target="_blank">
      {text}
      <LinkIcon><FontAwesomeIcon icon={faLink} /></LinkIcon>
    </StyledLink>
  )
}

const StyledLink = styled.a<Theme>`
  display: inline-block;
  color: ${({ text }) => text};
  text-decoration: none;

  :after {
    display: block;
    content: '';
    border-bottom: 1px solid ${({ title }) => title};
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
    transform-origin: 0% 50%;
  }

  :hover:after {
    transform: scaleX(1);
  }
`

const LinkIcon = styled.span`
  margin-left: 5px;
  opacity: 0.5;
`

export default Link;