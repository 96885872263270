import styled from 'styled-components';
import Header from './components/Header';
import Content from './components/Content'
import Footer from './components/Footer'
import { Theme, ThemeColor, ThemeProvider, useTheme } from './context/Theme'
import { useEffect, useState } from 'react';

interface PageProps {
  setTheme: (theme: ThemeColor) => void;
}

function Page({ setTheme }: PageProps) {
  const theme = useTheme();

  return (
    <StyledApp theme={theme}>
      <Header setTheme={setTheme} />
      <Content />
      <Footer />
    </StyledApp>
  );
}

function App() {
  const [theme, setTheme] = useState<ThemeColor>(localStorage.getItem('naoufel.co-theme') as ThemeColor || 'white');

  useEffect(() => {
    localStorage.setItem('naoufel.co-theme', theme);
  }, [theme])

  return (
    <ThemeProvider value={theme}>
      <Page setTheme={setTheme} />
    </ThemeProvider>
  );
}

const StyledApp = styled.div<{ theme: Theme }>`
  box-sizing: border-box;
  min-height: 100vh;
  width: 100vw;
  max-width: 100vw;
  background-color: ${({ theme: { background } }) => background};
  color: ${({ theme: { text } }) => text};
  display: flex;
  flex-direction: column;
  padding: 0 25%;
  transition: .2s padding, .2s background-color, .2s color;

  @media (max-width: 900px) {
    padding: 0 5%;
  }
`

export default App;
