import { HTMLAttributes, useCallback } from "react";
import styled from "styled-components";

export enum Nacomojis {
  Angry = 'angry',
  Astonished = 'astonished',
  BadlySurprised = 'badly_surprised',
  Clown = 'clown',
  Fiesta = 'fiesta',
  Grimacing = 'grimacing',
  Idea = 'idea',
  Neutral = 'neutral',
  Smiling =  'smiling',
  SmilingHearts = 'smiling_hearts'
}

export const nacomojiList = Object.values(Nacomojis);

const url = '/logo/naco';

interface NacomojiProps extends HTMLAttributes<HTMLImageElement> {
  nacomoji: 'naco' | Nacomojis;
  color?: 'default' | 'white';
  type?: 'svg' | 'png';
  scale?: 0.5 | 1 | 2 | number;
  full?: boolean;
}

const Nacomoji = ({ nacomoji, color = 'default', full, type = 'svg', scale = 1, ...props }: NacomojiProps) => {
  const urlBuilder = useCallback((): string => {
    const folder = full ? color === 'default' ? 'full' : `${color}_full` : color;
    const typeFolder = type === 'png' ? `${scale}x` : type.toUpperCase();
    const name = nacomoji === 'naco' ? 'naco' : `nacomoji_${nacomoji}`;
    const suffix = scale === 1 ? '' : `@${scale}x`;

    return `${url}/${folder}/${typeFolder}/${name}${suffix}.${type}`;
  }, [nacomoji, color, full, type, scale])

  return (
    <StyledNacomoji {...props} src={urlBuilder()} alt={nacomoji} />
  )
}

const StyledNacomoji = styled.img`
  height: 100%;
`

export default Nacomoji;