import styled from "styled-components";

export const Title = styled.div`
  font-family: 'Montserrat', sans-serif;
  font-weight: bold;
`

export const Text = styled.p`
  font-size: 14px;
  margin: 0;
`