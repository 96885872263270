import { faMoon, faSun } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';
import { ThemeColor, useTheme } from '../../context/Theme';
import Avatar from '../Avatar'
import { Title } from '../utils/Styled'

interface HeaderProps {
  setTheme: (theme: ThemeColor) => void;
}

const Header = ({ setTheme }: HeaderProps) => {
  const theme = useTheme();

  return (
    <StyledHeader>
      <None />

      <Left>
        <Avatar />
        <Title style={{ marginLeft: 20 }}>
          Naoufel Berrada
        </Title>
      </Left>

      <Right>
        <ThemeSelector>
          <FontAwesomeIcon
            icon={theme.theme === 'white' ? faMoon : faSun}
            onClick={() => setTheme(theme.theme === 'white' ? 'dark' : 'white')}
          />
        </ThemeSelector>
      </Right>
    </StyledHeader>
  )
}

const StyledHeader = styled.div`
  height: 60px;
  padding: 10px 0;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const None = styled.div`
  display: none;
  width: 0;
  height: 0;

  @media (max-width: 900px) {
    display: inline;
  }
`

const Left = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  height: 100%;
`

const Right = styled.div`
  align-items: center;
`

const ThemeSelector = styled.div`
  opacity: 0.5;
  cursor: pointer;
  transition: .2s opacity;

  :hover {
    opacity: 1;
  }
`

export default Header;