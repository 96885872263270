import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

interface ContactButtonProps {
  icon: IconDefinition;
  color: string;
  children: React.ReactNode;
  link: string;
}

function ContactButton({ icon, color, children, link }: ContactButtonProps) {
  return (
    <StyledContactButton target="_blank" href={link} hoverColor={color}>
      <FontAwesomeIcon icon={icon} />
    </StyledContactButton>
  )
}


interface StyledContactButtonProps {
  hoverColor: string;
}

const StyledContactButton = styled.a<StyledContactButtonProps>`
  text-decoration: none;
  color: rgba(150, 150, 150);
  transition: .2s color;

  :hover {
    color: ${({ hoverColor }) => hoverColor};
  }
`

export default ContactButton;