import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHeart } from '@fortawesome/free-solid-svg-icons';
import { faDiscord, faGithub, faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { useState } from 'react';

import ContactButton from './ContactButton';
import { Text } from '../utils/Styled';
import Nacomoji, { nacomojiList, Nacomojis } from '../Nacomoji'
import { useTheme } from '../../context/Theme';

interface FooterProps {};

function TransformingNaco() {
  const [nacomoji, setNacomoji] = useState<'naco' | Nacomojis>('naco');
  const theme = useTheme();

  return (
    <Nacomoji
      onMouseEnter={() => setNacomoji(nacomojiList[Math.floor(Math.random()*nacomojiList.length)])}
      onMouseLeave={() => setNacomoji('naco')}
      nacomoji={nacomoji}
      color={theme.theme === 'white' ? 'default' : 'white'}
      style={{
        opacity: nacomoji !== 'naco' ? '1' : '0.5',
        transition: '.2s opacity',
        cursor: 'pointer',
        height: '40px'
      }}
    />
  )
}

function Footer(props: FooterProps) {
  const theme = useTheme();

  return (
    <StyledFooter>
      <NacoContainer>
        <TransformingNaco />
      </NacoContainer>
      <BottomLine>
        <Text>
          Made with <FontAwesomeIcon icon={faHeart} /> and <FontAwesomeIcon icon={faGithub} />. Deployed on Vercel.
        </Text>
        <ContactContainer>
          <ContactButton color={theme.theme === 'white' ? '#2c3e50' : 'white'} icon={faEnvelope} link={'mailto:hello@naoufel.co?subject=Hello, Naoufel 🚀'}>hello@naoufel.co</ContactButton>
          <ContactButton color={theme.theme === 'white' ? '#252525' : 'white'} icon={faGithub} link={'https://github.com/nowlow'}>/nowlow</ContactButton>
          <ContactButton color={'#00acee'} icon={faTwitter} link={'https://twitter.com/naoufelberrada'}>/naoufelberrada</ContactButton>
          <ContactButton color={theme.theme === 'white' ?'#5865F2' : 'white'} icon={faDiscord} link={'https://discord.com/users/262663701671182336'}>Nowlow#4428</ContactButton>
        </ContactContainer>
        <Text>
          Naoufel corp, 2021.
        </Text>
      </BottomLine>
    </StyledFooter>
  );
}

const StyledFooter = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
`

const BottomLine = styled.div`
  padding: 10px 0;
  box-sizing: border-box;
  color: rgba(150, 150, 150);
  display: flex;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 640px) , (max-width: 1250px) {
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  @media (max-width: 900px) and (min-width: 640px) {
    flex-direction: row;
    align-items: start;
    text-align: initial;
  }
`

const ContactContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  >*:not(:last-child) {
    margin-right: 20px;
  }
`

const NacoContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: end;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 640px) , (max-width: 1250px) {
    justify-content: center;
  }

  @media (max-width: 900px) and (min-width: 640px) {
    justify-content: end;
  }
`

export default Footer;