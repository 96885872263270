import styled from "styled-components";
import { useTheme } from "../../context/Theme";
import { Text } from "../utils/Styled";
import Link from '../Link'

interface ContentProps {}

function Content(props: ContentProps) {
  const theme = useTheme();

  return (
    <StyledContent>
      <Section>
        <Section>
          <SectionTitle color={theme.title} style={{ marginTop: 0 }}>$ whoami</SectionTitle>
          <Text>
            Hi, I'm Naoufel Berrada, also known as nowlow.<br />
            I am a freelance software engineer working with <Link url={'https://kosmik.app'} text={"Kosmik"} /> as main developer and maintainer of the PWA product.<br /><br />
            I'm mainly interested in web and IoT, which are the two domains I've studied the most, notably by
            working for two years with Kosmik and having been two years head of Hardware at <Link url={'https://poc-innovation.fr'} text={"PoC innovation"} />.<br />
            I am also very interested in artificial intelligence and blockchain technologies.<br /><br />

            My native language is French but I'll have no problem exchanging in English.
          </Text>
        </Section>

        <Section>
          <SectionTitle color={theme.title}>$ whereis naoufel</SectionTitle>
          <Text>
            I'm currently based in Paris but I am open to any proposal worldwide.
          </Text>
        </Section>
      </Section>
    </StyledContent>
  )
}

const StyledContent = styled.div`
  flex: 1;
  width: 100%;
  padding: 30px 0;
`

const Section = styled.section`
  display: block;
`

const SectionTitle = styled.code`
  display: block;
  margin: 20px 0;
  font-weight: bold;
  color: ${({ color }) => color};
`

export default Content;